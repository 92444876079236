@import "../../styles/partials/fonts";
@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

body {
  margin: 0;
  font-family: "Poppins";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.this-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  margin: 3%;
  border-top: 1px black solid;
  padding: 1rem;
}

.dark-mode {
  background-color: black;
}

.header-title {
  color: black;
}

.dark-mode .header-title {
  color: white;
}

.dark-mode .nav-main-header {
  color: white;
}

.container {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 100vh;
}

.notes-list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.note.new {
  background-color: beige;
}

textarea {
  border: none;
  resize: none;
  background-color: beige;
}

textarea:focus {
  outline: none;
}

.save,
.toggle {
  background-color: #e1e1e1;
  border: none;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
}

.save:hover {
  background-color: #ededed;
  cursor: pointer;
}

.note {
  background-color: lightskyblue;
  border: solid 1px skyblue;
  box-shadow: black;
  box-shadow: solid 2px black;
  border-radius: 2rem;
  padding: 1rem;
  min-height: 170px;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-wrap;
}

.note-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-icon {
  cursor: pointer;
}

.search {
  display: flex;
  align-items: center;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 1.5em;
}

.search input {
  border: none;
  background-color: rgb(233, 233, 233);
  width: 100%;
}

.search input:focus {
  outline: none;
}
