@import url("https://fonts.googleapis.com/css2?family=The+Nautigal:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200;0,7..72,300;0,7..72,400;0,7..72,500;0,7..72,600;0,7..72,700;0,7..72,800;0,7..72,900;1,7..72,200;1,7..72,300;1,7..72,400;1,7..72,500;1,7..72,600;1,7..72,700;1,7..72,800;1,7..72,900&display=swap");

//e-reader mixins
@mixin desktop-reader-text {
  font-family: "Literata";
  justify-content: flex-start;
  text-align: left;
  padding: 16px;
  font-size: 1.25rem;
  line-height: 2.75rem;
}

//mobile font mixins
@mixin mobile__page-header {
  font-family: "Poppins";
  font-size: 1.75rem; //28px
  line-height: 2.25rem; //36px
}

@mixin mobile__sub-header {
  font-family: "Poppins";
  font-size: 1.25rem; //20px
  line-height: 1.75rem; //28px
}

@mixin mobile__labels-links-buttons {
  font-family: "Poppins";
  font-size: 0.81rem; //13px
  line-height: 1.25rem; //20px
}

@mixin mobile__table-header {
  font-family: "Poppins";
  font-size: 0.69rem; //11px
  line-height: 1rem; //16px
}

@mixin mobile__body-large {
  font-family: "Poppins";
  font-size: 0.94rem; //15px
  line-height: 1.63rem; //26px
}

@mixin mobile__body-medium {
  font-family: "Poppins";
  font-size: 0.81rem; //13px
  line-height: 1.25rem; //20px
}

@mixin mobile__body-small {
  font-family: "Poppins";
  font-size: 0.69rem; //11px
  line-height: 1rem; //16px
}

//tablet/desktop font mixins
@mixin tablet__page-header {
  font-family: "Poppins";
  font-size: 2rem; //32px
  line-height: 2.5rem; //40px
}

@mixin tablet__sub-header {
  font-family: "Poppins";
  font-size: 1.5rem; //24px
  line-height: 2rem; //32px
}

@mixin tablet__labels-links-buttons {
  font-family: "Poppins";
  font-size: 0.88rem; //14px
  line-height: 1.38rem; //22px
}

@mixin tablet__table-header {
  font-family: "Poppins";
  font-size: 0.75rem; //12px
  line-height: 1.13rem; //18px
}

@mixin tablet__body-large {
  font-family: "Poppins";
  font-size: 1rem; //16px
  line-height: 1.75rem; //28px
}

@mixin tablet__body-medium {
  font-family: "Poppins";
  font-size: 0.88rem; //14px
  line-height: 1.38rem; //28px
}

@mixin tablet__body-small {
  font-family: "Poppins";
  font-size: 0.75rem; //12px
  line-height: 1.13rem; //18px
}
