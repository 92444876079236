@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Rubik:wght@300;400&display=swap");
@import "../../styles/partials/fonts";
@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

// body {
//   background-color: #fff;
//   margin: 0px;
//   overflow: hidden;
//   font-family: arial;
//   color: #fff;
// }

.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 54%;
  overflow: hidden;
}

div.selected-player {
}

.reader-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-player {
  position: absolute;
  width: 100%;
  bottom: 0%;
  height: 2rem;
  border-radius: 0;
}

.book-iframe {
  height: 90vh;
  transform: scale(1.75);
  width: 81%;
}

.gatsbytext {
  color: white;
  border: white 2px solid;
  box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  max-width: 75vw;
  justify-content: center;
  margin-left: 14%;
  margin-right: 0;
  @include desktop-reader-text;
  margin-top: 10;
}

#reader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a1724;
  z-index: -1;
}

.reader-wrap {
  position: relative;
}

div.reader-content {
  position: relative;
  z-index: 2000;
  color: #fff;
  text-align: center;
  padding-top: 30px;
  justify-content: center;
}

.reader-title {
  background: radial-gradient(
    circle,
    #f8f8ff 0%,
    #cac9cd 20%,
    #9b9a9c 40%,
    #6d6a6a 60%,
    #3e3b39 80%,
    #100c07 90%,
    #c29b0c 100%
  );
  font-size: 2rem;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 400%;
  animation: gradient 20s linear infinite alternate;
  font-family: "Poppins";
  font-weight: 600;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
