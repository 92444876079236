@import "../../styles/partials/fonts";
@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.icon {
  height: 30%;
  width: 30%;
  align-self: center;
}

.login-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.login {
  display: flex;
  flex-direction: column;
  width: 22rem;
  box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem;
  margin-bottom: 1rem;
  mix-blend-mode: luminosity;

  &__title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    @include mobile__page-header;
    text-align: center;
    font-size: 1.5rem;
    border-top: 5px solid skyblue;
    padding-top: 0.75rem;
    align-self: center;
    color: black;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5rem;
  }

  &__button {
    margin-top: 1rem;
    background-color: skyblue;
    border: 1px solid skyblue;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: inherit;
    cursor: pointer;
    transition: background-color 0.15s;

    &:hover {
      background-color: #003fc2;
    }
  }

  &__message {
    border-left: 5px solid #ddd;
    padding: 0.75rem;
    margin-top: 2rem;
  }
}

.sign-up-text {
  color: white;
  @include mobile__body-medium;
}

.label {
  font-family: "Poppins";
  font-size: 1rem;
  color: black;
}

.field__label {
  font-family: "Poppins";
  font-size: 1rem;
  color: black;
}
