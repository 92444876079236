@import "../../styles/partials/fonts";
@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

* {
  font-family: "Poppins";
}

.currently-reading {
  font-family: "Poppins";
  font-size: 1.5rem;
  margin-left: 11rem;
}
.svg-inline--fa {
  font-size: 2em;
}

.arrow {
  color: black;
  font-size: 3rem;
}

.arrow-left {
  margin-left: -2rem;
}
.nav-back {
  font-size: 1.5rem;
  padding-left: 0.5rem;
  font-size: 1.5rem;
}

.hamburger {
  color: black;
  font-size: 10rem;
  display: flex;
}

.leyenda-header {
  display: flexz;
}

.nav-main-header {
  font-family: The Nautigal;
  color: black;
  font-size: 5rem;
  margin-top: 0%;
  justify-content: end;
}
.back-arrow-container {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  height: 4.75rem;
  margin-top: 0%;
  justify-content: center;
  place-content: space-between;

  @include tablet {
    padding: 0 3rem;
    height: 6rem;
  }

  @include desktop {
    padding: 0 6rem;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 1;
    background-color: white;
    padding: 1.5rem 1rem;
    transition: left 0.5s ease-in-out;

    &-menu {
      padding-top: 0.1rem;
      width: 68.25rem;
      margin-right: 2rem;
      color: black;
      font-size: 16px;
    }
    &-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-bottom: 1.5rem;
      margin: 0 -1rem;
      border-bottom: 2px solid black &--close {
        position: absolute;
        right: 1.5rem;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;

      &-items {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--link {
          text-decoration: none;
        }
      }

      &--arrow {
      }
    }

    &--open {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }
  &__logo {
    width: 12.25rem;
    height: 1rem;
  }
  &__shop {
    display: flex;

    &-search {
      padding-right: 1rem;
      width: 2.2rem;
    }
    &-bag {
      width: 1.1rem;
    }
  }
}
