@import "../../styles/partials/fonts";
@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.container-fluid.book-app {
  background: #141414 !important;
  color: #ffffff;
  font-family: "Poppins";
  margin-top: -1.4%;
}

.gal-title {
  margin-top: 2%;
}

.image-container {
  position: relative;
  transition: transform 0.2s;
}

.image {
  width: 328px;
  height: 495px;
  border-radius: 10px;
}

.image-container:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.image-container:hover .overlay {
  opacity: 1;
}

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  bottom: 0;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.detail-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(57, 57, 57, 0.6), rgba(57, 57, 57, 0.6));
}

.selected__book {
  &-text {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
  }

  &-title {
  }

  &-author {
  }

  &-rank {
  }

  &-desc {
  }

  &-details {
    @include tablet {
      background-color: rgb(57, 57, 57);
      padding: 2rem;
      display: flex;
      border-radius: 24px;
      box-shadow: #ffffff 5px 5px blur(4%);
      width: 75%;
      border: 5px solid
        linear-gradient(
          -45deg,
          #e4efe9 0%,
          #93a5cf 6%,
          #80d0c7 12%,
          #13547a 18%,
          #3f51b1 24%,
          #5a55ae 30%,
          #7b5fac 36%,
          #8f6aae 42%,
          #a86aa4 48%,
          #cc6b8e 54%,
          #f18271 60%,
          #f3a469 66%,
          #f7c978 72%,
          #dfa579 78%,
          #c79081 85%,
          #434343 92%,
          #000000 100%
        );
      animation: gradient 1s ease infinite;
    }

    @keyframes gradient {
      0% {
        background-position: 0%;
      }
      50% {
        background-position: 100%;
      }
      100% {
        background-position: 0%;
      }
    }
  }
}

// $duration = 450ms
// $tileWidth = 250px
// $tileHeight = ($tileWidth / (16/9))
// $iconSize = 50px
// $growFactor = 1.5 // 1.5 times the original size

// $moveLeft = -($tileWidth * ($growFactor - 1) / 2)
// $moveRight = $tileWidth * ($growFactor - 1)

// body, html
//   padding: 0 10px;
//   margin: 0;
//   background: #0E0F11;
//   color: #ecf0f1;
//   font-family: 'Open Sans', sans-serif;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   width: 100%;

// * { box-sizing: border-box }

// h1, p
//   text-align: center

// p
//   width: 100%;
//   max-width: 500px;
//   margin: auto;

// a:link
// a:hover
// a:active
// a:visited
//   transition: color 150ms;
//   color: #95a5a6;
//   text-decoration: none;

// a:hover
//   color: #7f8c8d;
//   text-decoration: underline;

// .contain
//   width: 100%;

.row {
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

// .row__inner
//   transition: $duration transform;
//   font-size: 0;
//   white-space: nowrap;
//   margin: ($tileHeight / 2) 0;
//   padding-bottom: 10px // Account for OS X scroll bar

// .tile
//   position: relative
//   display: inline-block
//   width: $tileWidth
//   height: $tileHeight
//   margin-right: 10px
//   font-size: 20px
//   cursor: pointer
//   transition: $duration all
//   transform-origin: center left

// .tile__img
//   width: $tileWidth
//   height: $tileHeight
//   object-fit: cover

// .tile__details
//   position: absolute
//   bottom: 0
//   left: 0
//   right: 0
//   top: 0
//   font-size: 10px
//   opacity: 0
//   background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%)
//   transition: $duration opacity

//   &:after
//   &:before
//     content: ''
//     position absolute
//     top: 50%
//     left: 50%
//     display: black

//   &:after
//     margin-top: (-($iconSize / 2))
//     margin-left: (-($iconSize / 2))
//     width: $iconSize
//     height: $iconSize
//     border: 3px solid #ecf0f1
//     line-height: $iconSize
//     text-align: center
//     border-radius: 100%
//     background: rgba(0,0,0,0.5)
//     z-index: 1

//   &:before
//     content: '▶'
//     left: 0
//     width: 100%
//     font-size: 30px
//     margin-left: 7px
//     margin-top: -18px
//     text-align: center
//     z-index: 2

//   .tile:hover &
//     opacity: 1

// .tile__title
//   position absolute
//   bottom: 0
//   padding: 10px

// // *
// // * Hover effects
// // *

// // We assume when you hover .row, you're also hovering a .tile
// // First, move the whole row towards the left
// .row__inner:hover
//   transform: translate3d($moveLeft, 0, 0)

// .row__inner:hover .tile

//   // Fade out every tile
//   opacity: .3

//   // And then grow the current tile and make it opaque again
//   &:hover
//     transform: scale($growFactor)
//     opacity: 1

// // And finally move all tiles right of the current tile to the right,
// // so its flush against the hovered tile
// .tile:hover ~ .tile
//   transform: translate3d($moveRight, 0, 0)
